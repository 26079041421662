import { Controller } from "@hotwired/stimulus"

// Thi
export default class extends Controller {
  static targets = ["carousel", "slide", "button"];

  connect() {
    this.updateButtonStyles();
    this.observeCarousel();
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  observeCarousel() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.currentIndex = this.slideTargets.indexOf(entry.target);
          this.updateButtonStyles();
        }
      });
    }, {
      root: this.carouselTarget,
      threshold: 0.5 // Adjust as needed to detect when a slide is mostly visible
    });

    this.slideTargets.forEach(slide => this.observer.observe(slide));
  }

  updateButtonStyles() {
    this.buttonTargets.forEach((button, index) => {
      button.classList.toggle('bg-gray-300', index === this.currentIndex);
      button.classList.toggle('bg-gray-500', index !== this.currentIndex);
    });
  }

  handleButtonClick(event) {
    this.currentIndex = this.buttonTargets.indexOf(event.currentTarget);
    this.slideTargets[this.currentIndex].scrollIntoView({ behavior: 'smooth' });
    this.updateButtonStyles();
  }

}